/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body {
   margin: 0;
   font-family: Open Sans;
   font-size:14px;
   overflow: hidden;
 }
// @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

@font-face {
  font-family: Satisfy;
  src: url(assets/fonts/Satisfy-Regular.ttf) format("opentype");
}
@font-face {
  font-family: Kalam;
  src: url(assets/fonts/Kalam-Regular.ttf) format("opentype");
}
@font-face {
  font-family: Cookie;
  src: url(assets/fonts/Cookie-Regular.ttf) format("opentype");
}
@font-face {
  font-family: OpenSans;
  src: url(assets/fonts/OpenSans-Regular.ttf) format("opentype");
}
@font-face {
  font-family: OpenSansBold;
  src: url(assets/fonts/OpenSans-Bold.ttf) format("opentype");
}
@import url('https://fonts.googleapis.com/css2?family=Chivo:wght@900&family=Inter:wght@600&family=Open+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chivo&family=Open+Sans&display=swap');


.font_title{
  color: #151515;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.font_sub_title {
  color: #151515;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
//header text is in header.css .out
.font_text{
  font-size: 16px;
}
.filler{
  flex: 1;
  display: flex;
}
.abs_center{
  position: absolute;
  top:50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
//used as the second line in assets wher the controls go
.top_line_container{
  position: absolute;
  left: 40px;
  width: calc(100% - 60px);
  overflow: hidden;
  height: fit-content;
}
.top_line{
  display: flex;
  // min-width: 650px
    width:100%;
}
.name{
  position: absolute;
  left: 20px;
  bottom: 10px;
  background: #151515b0;
  color: white;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  overflow: hidden;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #d9d9d9;
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.input{
  width: 240px;
  height: 44px;
  color: black;
  border-radius: 8px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  background: white;
  padding-left: 10px;
  display: block;
  background-repeat: no-repeat;
  background-position: 10px 50%;
  margin-bottom: 10px;
  margin-top: 2px;
    border-radius: 4px;
}
input{
  border: 1px solid #f0f0f0;
}
input:focus{
  outline: none;
  border: 1px solid #3B5FE0;
}
.invalid{
  color:red;
}
